import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useTranslation } from "react-i18next";

const SidebarMenuMain = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <div
        className=" text-white text-uppercase my-4 text-dark"
        style={{ paddingLeft: "8px" }}
      >
        {t("MAIN")}
      </div> */}
      <SidebarMenuItem
        to="/dashboard"
        icon="element-equal"
        title={t("DASHBOARD")}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItemWithSub
        to="/user-management"
        title={t("USER.MANAGEMENT")}
        icon="abstract-28"
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="user-management/users"
          title={t("USERS")}
          hasBullet={true}
        />

        {/* <SidebarMenuItem
          to="user-management/roles"
          title={t("ROLES")}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="user-management/permissions"
          title={t("PERMISSIONS")}
          hasBullet={true}
        /> */}
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItem
        to="/product-Subscription"
        icon="element-5"
        title={t("PRODUCT.SUBSCRIPTION")}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/billing-payment"
        icon="book"
        title={t("BILLING.PAYMENT")}
        fontIcon="bi-app-indicator"
      /> */}

      <SidebarMenuItemWithSub
        to="/company-managment"
        title={t("COMPANY.MANAGEMENT")}
        icon="abstract-28"
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="company-management/profile"
          title={t("COMPANY.PROFILE")}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="company-management/company-branches"
          title={t("COMPANY.BRANCHES")}
          hasBullet={true}
        />
        {/* <SidebarMenuItem
          to="company-management/services"
          title={t("COMPANY.SERVICES")}
          hasBullet={true}
        /> */}

        <SidebarMenuItem
          to="company-management/rebranding"
          title={t("COMPANY.REBRANDING")}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="company-management/preferences"
          title={t("COMPANY.PREFERENCES")}
          hasBullet={true}
        />
        <SidebarMenuItem
          to="company-management/legal"
          title={t("COMPANY.LEGAL")}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      {/* <div
        className="text-uppercase my-4 text-dark"
        style={{ paddingLeft: "8px" }}
      >
        {t("OTHER")}
      </div>
      <SidebarMenuItem
        to="/report"
        icon="abstract-26"
        title={t("REPORT")}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/help-support"
        icon="question"
        title={t("HELP.SUPPORT")}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/pricing"
        icon="bank"
        title={t("PRICING")}
        fontIcon="bi-app-indicator"
      /> */}
    </>
  );
};

export { SidebarMenuMain };
